import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"

import "babel-polyfill"
import "bootstrap-icons/font/bootstrap-icons.min.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "react-app-polyfill/ie11"

import * as i18n from "./services/i18n"
import App from "./App"
import { unregister } from "./registerServiceWorker"

import "./index.css"

// Render the application
const rootElement = document.getElementById("root");
const rendererApp = () => {
    ReactDOM.render((
        <BrowserRouter>
            <App />
        </BrowserRouter>
    ), rootElement);
    unregister();
}

// Initialize internationalization to support different languages and pass the data values to the app
i18n.initialize(rendererApp)
