import React from "react"

import PageNavigator from "../../components/PageNavigator"
import TranslatedText from "../../components/TranslatedText"
import { __ } from "../../services/i18n"

import financeImage from '../../resources/images/considerations/finance.png'
import peaceImage from '../../resources/images/considerations/peace.png'
import supportImage from '../../resources/images/considerations/support.png'

import './ConsiderationsPage.css'

const ConsiderationsPage = ({ navigation }) => (
    <section className="__learn_consyderations__">
        <h1>{ __('to_consyder:heading') }</h1>

        <p>{ __('to_consyder:paragraph_1') }</p>

        <div className="row consideration-point top-margin">
            <div className="col-12 col-md-4 col-lg-3">
                <img src={ peaceImage } alt="peace" className="img-fluid" />
            </div>
            <div className="col-12 col-md-8 col-lg-9">
                <h2 className="color-text-teal">{ __('to_consyder:section_1_heading') }</h2>

                <p>{ __('to_consyder:section_1_paragraph_1') }</p>
                <p>{ __('to_consyder:section_1_paragraph_2') }</p>
            </div>
        </div>

        <div className="row consideration-point">
            <div className="col-12 col-md-4 col-lg-3">
                <img src={ financeImage } alt="finance" className="img-fluid" />
            </div>
            <div className="col-12 col-md-8 col-lg-9">
                <h2>{ __('to_consyder:section_2_heading') }</h2>

                <p>{ __('to_consyder:section_2_paragraph_1') }</p>
                <p>{ __('to_consyder:section_2_paragraph_2') }</p>
            </div>
        </div>

        <div className="row consideration-point">
            <div className="col-12 col-md-4 col-lg-3">
                <img src={ supportImage } alt="finance" className="img-fluid" />
            </div>
            <div className="col-12 col-md-8 col-lg-9">
                <h2>{ __('to_consyder:section_3_heading') }</h2>

                <p><TranslatedText translationKey='to_consyder:section_3_paragraph_1' /></p>
            </div>
        </div>

        <PageNavigator navigation={ navigation } />
    </section>
)

export default ConsiderationsPage
