import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import TranslatedText from "../../TranslatedText"
import { __ } from "../../../services/i18n"

import threeToEightPercentRiskImage from "../../../resources/images/risks/3-8_percent_risk.png"
import twoToThreePercentRiskImage from "../../../resources/images/risks/2-3_percent_risk.png"

const CancerRiskAfterLumpectomyAndRadiationAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{ __('procedure_lumpectomy:accordion_5_heading') }</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_lumpectomy:accordion_5_paragraph_1" /></p>
            <img
                alt="On average, about 3 to 8 of every 100 young adults who choose this treatment for their breast cancer will have their breast cancer come back in their treated breast and chest wall area."
                className="img-fluid mx-auto"
                src={ threeToEightPercentRiskImage }
            />
            <hr />

            <p><TranslatedText translationKey="procedure_lumpectomy:accordion_5_paragraph_2" /></p>
            <img
                alt="On average, about 2 to 3 of every 100 young adults who choose this treatment for their breast cancer will develop cancer in their other breast."
                className="img-fluid mx-auto margin-bottom-14"
                src={ twoToThreePercentRiskImage }
            />

            <p className="color-primary-blue">
                <strong><TranslatedText translationKey="procedure_lumpectomy:accordion_5_paragraph_3" /></strong>
            </p>
            <p><TranslatedText translationKey="procedure_lumpectomy:accordion_5_paragraph_4" /></p>
            <hr />

            <h4 className="color-primary-blue"><TranslatedText translationKey="procedure_lumpectomy:accordion_5_paragraph_5" /></h4>
            <p><TranslatedText translationKey="procedure_lumpectomy:accordion_5_paragraph_6" /></p>
        </AccordionItemBody>
    </AccordionItem>
)

export default CancerRiskAfterLumpectomyAndRadiationAccordionItem
