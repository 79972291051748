import React from "react"
import {withRouter} from "react-router-dom"

import LearnMorePageNavigation from "./subnav/LearnMorePageNavigation"
import LocaleSwitcher from "../i18n/LocaleSwitcher"
import Navbar from "./Navbar"

import logo from "../../resources/images/consyder_full-color.png"

import "./Header.css"

const Header = ({ history }) => {
    const [ showSideNav, setShowSideNav ] = React.useState(false)

    // Only show the sidenav on pages under the /learn[/*] route
    React.useEffect(() => {
        setShowSideNav(history.location.pathname.startsWith('/learn'))
    }, [history.location.pathname])

    return (
        <header className="__header__">
            <div className="text-center col-md-12">
                <img className="img-fluid" src={ logo } alt="Consyder Logo" width={ 537 } />
                <div className="text-right" style={{maxWidth: "950px", margin: "auto"}}>
                    <LocaleSwitcher />
                </div>
            </div>

            <div className="no-print">
                <Navbar enableLearnTab={ showSideNav }/>
                { showSideNav && <LearnMorePageNavigation /> }
            </div>
        </header>
    )
}

export default withRouter(Header)
