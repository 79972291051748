import React from "react"
import { Link } from "react-router-dom"
import { Trans } from "react-i18next"

const TranslatedText = ({
    dynamicVars = undefined,
    translationKey,
}) => (
    <Trans
        i18nKey={translationKey}
        components={
            {
                paragraph: <p />,
                ulist: <ul />,
                litem: <li />,
                boldRegular: <strong />,
                primaryBlue: <span className="color-primary-blue" />,
                pbold: <span className="weight-600 color-text-teal" />,
                BoldBlue: <span className="weight-600 color-primary-blue" />,
                BoldTeal: <span className="weight-600 color-text-teal" />,
                hyperlink: <a className="color-text-teal" target="_blank" rel="noopener noreferrer" />,
                hyperlinkPrimaryBlue: <a className="color-primary-blue" target="_blank" rel="noopener noreferrer" />,
                gotoresources: <Link to="/resources" />
            }
        }
        values={dynamicVars}
    />
)

export default TranslatedText
