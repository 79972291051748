import React from "react"

import "./OtherTextField.css"

const OtherTextField = ({
    id,
    name,
    onChange,
    storedValue
}) => {
    return (
        <div className="__other_text_field__">
            <div className="form-group">
                <input
                    aria-label="other text field"
                    className="form-control"
                    defaultValue={ storedValue }
                    id={ id }
                    name={ name }
                    onChange={ onChange }
                    type="text"
                />
            </div>
        </div>
    )
}

export default OtherTextField
