import React from 'react'

import TranslatedText from "../components/TranslatedText"
import { __ } from "../services/i18n"

const AboutPage = () => (
    <section className="__about__">
        <h1>{ __('about:heading')}</h1>

        <p><TranslatedText translationKey='about:paragraph_1' /></p>

        <p>{ __('about:paragraph_2') }</p>

        <h2>{ __('about:heading_2') }</h2>

        <p>{ __('about:paragraph_3') }</p>

        <p>{ __('about:paragraph_4') }</p>
    </section>
)

export default AboutPage
