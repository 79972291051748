import React from 'react'

import OtherProceduresAccordion from "../accordions/OtherProceduresAccordion"
import { __ } from "../../services/i18n"

const OtherProceduresInformation = () => (
    <section className="__other_info__ margin-top">
        <h2>{ __('procedure_other:heading') }</h2>

        <div className="row">
            <div className="col-12">
                <OtherProceduresAccordion />
            </div>
        </div>
    </section>
)

export default OtherProceduresInformation
