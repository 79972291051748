import React from "react"

import "react-accessible-accordion/dist/fancy-example.css"

import LumpectomyProcedureInformation from "../../components/procedures/LumpectomyProcedureInformation"
import MastectomyProcedureInformation from "../../components/procedures/MastectomyProcedureInformation"
import OtherProceduresInformation from "../../components/procedures/OtherProceduresInformation"
import PageNavigator from "../../components/PageNavigator"
import TranslatedText from "../../components/TranslatedText"
import { __ } from "../../services/i18n"

import './TreatmentOptionsPage.css'

const TreatmentOptionsPage = ({ navigation }) => (
    <section className="__treatment_options__">
        <h1>{ __('treatment_options:heading') }</h1>

        <p>{ __('treatment_options:paragraph_1') }</p>
        <p><TranslatedText translationKey="treatment_options:paragraph_2" /></p>

        <LumpectomyProcedureInformation />
        <MastectomyProcedureInformation />
        <OtherProceduresInformation />

        <PageNavigator navigation={ navigation } />
    </section>
)

export default TreatmentOptionsPage
