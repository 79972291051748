import { useState, useEffect } from 'react'

/**
 * Immutable "enumeration" representing each screen mode state.
 *
 * NOTE: Intentionally immutable, should never be modified outside this file.
 *
 * @type {{xl: number, md: number, sm: number, xs: number, lg: number}}
 */
export const screenModes = {
    xs: 0,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4
}
Object.freeze(screenModes)

/**
 * Immutable object listing the min screen size bootstrap defines for screen sizes.
 *
 * NOTE:  Intentionally immutable, should never be modified outside this file.
 *
 * @type {{xl: number, md: number, sm: number, xs: number, lg: number}}
 */
export const screenMinWidths = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
}
Object.freeze(screenMinWidths)

/**
 * Retrieves the width of the viewport.
 * Created as a method in case there is ever a need to update the algorithm on a particular browser.
 *
 * @returns { number }
 */
export const getViewportWidth = () => (window.innerWidth)

export const getScreenMode = (screenWidth) => {
    if (screenWidth > screenMinWidths.xl) {
        return screenModes.xl
    } else if (screenWidth > screenMinWidths.lg) {
        return screenModes.lg
    } else if (screenWidth > screenMinWidths.md) {
        return screenModes.md
    } else if (screenWidth > screenMinWidths.sm) {
        return screenModes.sm
    } else {
        return screenModes.xs
    }
}

export const getCurrentScreenMode = () => (getScreenMode(getViewportWidth()))

const useScreenMode = () => {
    const [screenMode, setScreenMode] = useState(getCurrentScreenMode)

    useEffect(() => {
        const handleScreenResize = () => {
            setScreenMode(getCurrentScreenMode)
        }

        window.addEventListener('resize', handleScreenResize)

        return () => {
            window.removeEventListener('resize', handleScreenResize)
        }
    })

    return screenMode
}

export default useScreenMode
