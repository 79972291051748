import React from 'react'

import NumberScale from "./NumberScale"
import TranslatedText from "./TranslatedText"
import {__} from "../services/i18n"

const QuestionScale = ({
    className,
    savedValues,
    onSaveProgress
}) => {
    const handleScaleChange = (value, scale) => {
        onSaveProgress({
            values: {
                ...savedValues,
                [value]: scale
            }
        })
    }

    const contentArray = [
        {
            stateKey: "question_1",
            leadText: __('whats_important:question_1'),
            responseBody: (
                <div>
                    <p><TranslatedText translationKey="whats_important:question_1_response_paragraph_1" /></p>
                    <p><TranslatedText translationKey="whats_important:question_1_response_paragraph_2" /></p>
                    <p><TranslatedText translationKey="whats_important:question_1_response_paragraph_3" /></p>
                </div>
            )
        },
        {
            stateKey: "question_2",
            leadText: __('whats_important:question_2'),
            responseBody: (
                <div>
                    <p><TranslatedText translationKey="whats_important:question_2_response_paragraph_1" /></p>
                    <p><TranslatedText translationKey="whats_important:question_2_response_paragraph_2" /></p>
                    <p><TranslatedText translationKey="whats_important:question_2_response_paragraph_3" /></p>
                </div>
            )
        },
        {
            stateKey: "question_3",
            leadText: __('whats_important:question_3'),
            responseBody: (
                <div>
                    <p><TranslatedText translationKey="whats_important:question_3_response_paragraph_1" /></p>
                    <p><TranslatedText translationKey="whats_important:question_3_response_paragraph_2" /></p>
                </div>
            )
        },
        {
            stateKey: "question_4",
            leadText: __('whats_important:question_4'),
            responseBody: (
                <div>
                    <p><TranslatedText translationKey="whats_important:question_4_response_paragraph_1" /></p>
                    <p><TranslatedText translationKey="whats_important:question_4_response_paragraph_2" /></p>
                    <p><TranslatedText translationKey="whats_important:question_4_response_paragraph_3" /></p>
                </div>
            )
        },
        {
            stateKey: "question_5",
            leadText: __('whats_important:question_5'),
            responseBody: (
                <div>
                    <p><TranslatedText translationKey="whats_important:question_5_response_paragraph_1" /></p>
                    <p><TranslatedText translationKey="whats_important:question_5_response_paragraph_2" /></p>
                    <p><TranslatedText translationKey="whats_important:question_5_response_paragraph_3" /></p>
                    <p><TranslatedText translationKey="whats_important:question_5_response_paragraph_4" /></p>
                </div>
            )
        },
        {
            stateKey: "question_6",
            leadText: __('whats_important:question_6'),
            responseBody: (
                <div>
                    <p><TranslatedText translationKey="whats_important:question_6_response_paragraph_1" /></p>
                    <p><TranslatedText translationKey="whats_important:question_6_response_paragraph_2" /></p>
                    <p><TranslatedText translationKey="whats_important:question_6_response_paragraph_3" /></p>
                </div>
            )
        },
        {
            stateKey: "question_7",
            leadText: __('whats_important:question_7'),
            responseBody: (
                <div>
                    <p><TranslatedText translationKey="whats_important:question_7_response_paragraph_1" /></p>
                    <p><TranslatedText translationKey="whats_important:question_7_response_paragraph_2" /></p>
                    <p><TranslatedText translationKey="whats_important:question_7_response_paragraph_3" /></p>
                    <p><TranslatedText translationKey="whats_important:question_7_response_paragraph_4" /></p>
                </div>
            )
        },
    ]

    return (
        <div className={ className }>
        {
            contentArray.map((entry, idx) => (
                <NumberScale
                    key={ idx }
                    content={ entry.leadText }
                    onScaleSelect={ handleScaleChange }
                    responseMessage={ entry.responseBody }
                    savedValue={ savedValues[entry.stateKey] }
                    scale={ 10 }
                    value={ entry.stateKey }
                />
            ))
        }
        </div>
    )
}

export default QuestionScale
