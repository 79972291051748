import React from "react"

import TranslatedText from "../TranslatedText"
import {CurrentYear} from "../../services/DateService"
import {__} from "../../services/i18n"

import pageLinks from "../../resources/data/page_links.json"

import "./Footer.css"

const Footer = () => (
    <footer className="__footer__ no-print">
        <p className="text-center">
            {__('footer:copyright', {year: CurrentYear})}&nbsp;|&nbsp;
            <a href={ pageLinks.wcm.privacyPolicy } rel="noopener noreferrer" target="_blank">{__('footer:privacy_policy')}</a>&nbsp;|&nbsp;
            <a href={ pageLinks.wcm.legalStatement } rel="noopener noreferrer" target="_blank">{__('footer:legal_statements')}</a>
        </p>
        <p className="text-center">
            <TranslatedText translationKey="footer:developed_by" />
        </p>
    </footer>
)

export default Footer
