import React from "react"
import { Link } from "react-router-dom"

import TranslatedText from "../components/TranslatedText"
import { __ } from "../services/i18n"

const HomePage = () => (
    <section>
        <p className="leading-1">
            <TranslatedText translationKey='home:paragraph_1' />
        </p>

        <p className="leading-1">{ __('home:paragraph_2') }</p>

        <ul className="leading-1">
            <li>{ __('home:paragraph_2_item_1') }</li>
            <li>{ __('home:paragraph_2_item_2') }</li>
        </ul>

        <div className="alert alert-app-info leading-1 weight-600 font-style-italic my-sm-4">
            <ul>
                <li>{ __('home:alert_item_1') }</li>
                <li>{ __('home:alert_item_2') }</li>
                <li>{ __('home:alert_item_3') }</li>
            </ul>
        </div>

        <div className="text-center">
            <Link className="btn btn-primary leading-1 mx-auto" to="/learn/treatment-options">
                { __('home:btn_get_started') }
            </Link>
        </div>
    </section>
)

export default HomePage
