import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import TranslatedText from "../../TranslatedText"
import {__} from "../../../services/i18n"

const RadiationAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{__('procedure_mastectomy:accordion_6_heading')}</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_6_paragraph_1" /></p>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_6_paragraph_2" /></p>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_6_paragraph_3" /></p>
            <ul>
                <li><TranslatedText translationKey="procedure_mastectomy:accordion_6_paragraph_3_item_1" /></li>
                <li><TranslatedText translationKey="procedure_mastectomy:accordion_6_paragraph_3_item_2" /></li>
                <li><TranslatedText translationKey="procedure_mastectomy:accordion_6_paragraph_3_item_3" /></li>
            </ul>
        </AccordionItemBody>
    </AccordionItem>
)

export default RadiationAccordionItem
