import React from "react"
import {useTranslation} from "react-i18next"

import {supportedLanguages} from "../../services/i18n"

const LocaleSwitcher = () => {
    const {i18n} = useTranslation()

    const languageCount = Object.keys(supportedLanguages).length

    return (
        <div>
        {
            languageCount > 1 && (
                <select
                    value={i18n.resolvedLanguage}
                    onChange={(e) => i18n.changeLanguage(e.target.value)}
                >
                {
                    Object.entries(supportedLanguages).map(([code, name]) => (
                        <option value={code} key={code}>
                            {name}
                        </option>
                    ))
                }
                </select>
            )
        }
        </div>
    )
}

export default LocaleSwitcher
