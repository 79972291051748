import React, { useState } from "react"
import { Route, withRouter } from "react-router-dom"
import { animateScroll } from "react-scroll"

import AboutPage from "./pages/AboutPage"
import ConsiderationsPage from "./pages/learn/ConsiderationsPage"
import Footer from "./components/layout/Footer"
import Header from "./components/layout/Header"
import HomePage from "./pages/HomePage"
import ImportancePage from "./pages/learn/ImportancePage"
import NextStepsPage from "./pages/learn/NextStepsPage"
import ResourcesPage from "./pages/ResourcesPage"
import SummaryPage from "./pages/learn/SummaryPage"
import TreatmentComparisonPage from "./pages/learn/TreatmentComparisonPage"
import TreatmentOptionsPage from "./pages/learn/TreatmentOptionsPage"

import "./App.css"

const App = ({ history }) => {
    const [ surveyValues, setSurveyValues ] = useState({})
    const [ followupValues, setFollowupValues ] = useState({})

    //  Scroll back to the top of page whenever the route changes
    React.useEffect(() => {
        animateScroll.scrollToTop({
            duration: 200
        })
    }, [history.location.pathname])

    // Define navigation rules for the "Learn More" pages
    const learnMoreNavigatorRules = {
        options: {
            prev_page: '/',
            next_page: '/learn/treatment-comparison'
        },
        compare: {
            prev_page: '/learn/treatment-options',
            next_page: '/learn/values-clarification'
        },
        importance: {
            prev_page: '/learn/treatment-comparison',
            next_page: '/learn/considerations'
        },
        to_consider: {
            prev_page: '/learn/values-clarification',
            next_page: '/learn/next-steps'
        },
        next_steps: {
            prev_page: '/learn/considerations',
            next_page: '/learn/summary'
        },
        summary: {
            prev_page: '/learn/next-steps',
            next_page: {
                route: '/resources',
                i18nKey: 'navigator:to_resources'
            }
        }
    }

    const handleSaveFollowupQuestions = ({ next }) => {
        setFollowupValues(next)
    }

    const handleSaveImportanceQuestions = ({ values }) => {
        setSurveyValues(values)
    }

    return (
        <div className="__app__ app">
            <Header />
            <div className="container full-width-print full-max-width-print my-0">
                <div className="row">
                    <div className="col-sm-12 full-max-width-print">
                        <main>
                            <Route exact path="/" component={ HomePage } />
                            <Route path="/resources" component={ ResourcesPage } />
                            <Route path="/about" component={ AboutPage } />

                            <Route path="/learn/treatment-options" render={ () => (
                                <TreatmentOptionsPage navigation={ learnMoreNavigatorRules.options } />
                            )} />
                            <Route path="/learn/treatment-comparison" render={ () => (
                                <TreatmentComparisonPage navigation={ learnMoreNavigatorRules.compare } />
                            )} />
                            <Route path="/learn/values-clarification" render={ () => (
                                <ImportancePage
                                    navigation={ learnMoreNavigatorRules.importance }
                                    onSaveProgress={ handleSaveImportanceQuestions }
                                    savedValues={ surveyValues }
                                />
                            )} />
                            <Route path="/learn/considerations" render={ () => (
                                <ConsiderationsPage navigation={ learnMoreNavigatorRules.to_consider } /> )
                            } />
                            <Route path="/learn/next-steps" render={ () => (
                                <NextStepsPage
                                    navigation={ learnMoreNavigatorRules.next_steps }
                                    onSaveProgress={ handleSaveFollowupQuestions }
                                    savedValues={ followupValues }
                                />
                            )} />
                            <Route path="/learn/summary" render={ () => (
                                <SummaryPage
                                    followupValues={ followupValues }
                                    navigation={ learnMoreNavigatorRules.summary }
                                    onSaveFollowupQuestion={ handleSaveFollowupQuestions }
                                    onSaveImportanceQuestion={ handleSaveImportanceQuestions }
                                    savedValues={ surveyValues }
                                />
                            )} />
                        </main>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withRouter(App)
