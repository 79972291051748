import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import BilateralMastectomyAccordion from "../BilateralMastectomyAccordion"
import TranslatedText from "../../TranslatedText"
import {__} from "../../../services/i18n"

const BilateralMastectomyAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{__('procedure_mastectomy:accordion_5_heading')}</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_5_paragraph_1" /></p>
            <BilateralMastectomyAccordion />
        </AccordionItemBody>
    </AccordionItem>
)

export default BilateralMastectomyAccordionItem
