export const toInt = (num) => {
  return parseInt(num, 10);
}

export const exists = (val) => {
  return val !== undefined;
}

export const isNotEmptyString = (val) => (
    exists(val) && val.trim() !== ''
)

export const numArray = (scale) => {
  const limit = parseInt(scale, 10);
  return Array.from({ length: limit }, (item, i) => i);
}

export const isString = (object) => (typeof object === "string" || object instanceof String)
