import React from 'react'
import {AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion"

import TranslatedText from "../../TranslatedText"
import {__} from "../../../services/i18n"

const TissueReconstructionAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{__('procedure_other:accordion_2_2_heading')}</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_other:accordion_2_2_paragraph_1" /></p>
            <ul>
                <li><TranslatedText translationKey="procedure_other:accordion_2_2_paragraph_1_item_1" /></li>
                <li><TranslatedText translationKey="procedure_other:accordion_2_2_paragraph_1_item_2" /></li>
            </ul>
        </AccordionItemBody>
    </AccordionItem>
)

export default TissueReconstructionAccordionItem
