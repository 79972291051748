import React from 'react'

import FollowupQuestions from "../../components/FollowupQuestions"
import PageNavigator from "../../components/PageNavigator"
import {__} from "../../services/i18n"

const NextStepsPage = ({
    navigation,
    onSaveProgress,
    savedValues
}) => (
    <section className="__next_steps__">
        <h1>{__('next_steps:heading')}</h1>
        <p className="alert alert-app-info">
            {__('next_steps:paragraph_1')}
        </p>

        <FollowupQuestions
            savedValues={ savedValues }
            onSelection={ onSaveProgress }
        />

        <PageNavigator navigation={ navigation } />
    </section>
)

export default NextStepsPage
