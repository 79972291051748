import React from 'react'

import LumpectomyAccordion from "../accordions/LumpectomyAccordion"
import TranslatedText from "../TranslatedText"
import { __ } from "../../services/i18n"

const LumpectomyProcedureInformation = () => (
    <section className="__lumpectomy_info__ margin-top">
        <h2>{ __('procedure_lumpectomy:heading') }</h2>

        <p><TranslatedText translationKey="procedure_lumpectomy:paragraph_1" /> </p>
        <p>{ __('procedure_lumpectomy:paragraph_2') }</p>
        <p>{ __('procedure_lumpectomy:paragraph_3') }</p>

        <div className="row">
            <div className="col-12">
                <LumpectomyAccordion />
            </div>
        </div>
    </section>
)

export default LumpectomyProcedureInformation
