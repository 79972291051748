import React from "react"
import { Accordion } from "react-accessible-accordion"

import AppearanceAndSensationAccordionItem from "./mastectomyItems/AppearanceAndSensationAccordionItem"
import BilateralMastectomyAccordionItem from "./mastectomyItems/BilateralMastectomyAccordionItem"
import CancerRiskAfterMastectomyAccordionItem from "./mastectomyItems/CancerRiskAfterMastectomyAccordionItem"
import RadiationAccordionItem from "./mastectomyItems/RadiationAccordionItem"
import RecoveryTimeAccordionItem from "./mastectomyItems/RecoveryTimeAccordionItem"
import ShortAndLongTermEffectsAccordionItem from "./mastectomyItems/ShortAndLongTermEffectsAccordionItem"

const MastectomyAccordion = () => (
    <Accordion className="margin-top">
        <ShortAndLongTermEffectsAccordionItem />
        <AppearanceAndSensationAccordionItem />
        <RecoveryTimeAccordionItem />
        <CancerRiskAfterMastectomyAccordionItem />
        <BilateralMastectomyAccordionItem />
        <RadiationAccordionItem />
    </Accordion>
)

export default MastectomyAccordion
