import React from 'react'
import { NavLink } from 'react-router-dom'
import { __ } from "../../services/i18n"

import "./Navbar.css"

const Navbar = ({ enableLearnTab }) => (
    <nav className="__navbar__ navbar navbar-expand-sm navbar-dark bg-custom static-top">
        <span></span>
        <button
            aria-controls="navbarToggle"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbarToggle"
            data-toggle="collapse"
            type="button"
        >
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarToggle">
            <div className="navbar-nav mx-auto">
                <NavLink exact className="nav-item nav-link" to="/">{ __('navigation:home') }</NavLink>
                <NavLink
                    className={ `nav-item nav-link ${enableLearnTab ? 'active' : ''}`}
                    to="/learn/treatment-options"
                >
                    { __('navigation:learn_more') }
                </NavLink>
                <NavLink className="nav-item nav-link" to="/resources">{ __('navigation:resources') }</NavLink>
                <NavLink className="nav-item nav-link" to="/about">{ __('navigation:about') }</NavLink>
            </div>
        </div>
    </nav>
)

export default Navbar
