import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import {__} from "../../../services/i18n";
import TranslatedText from "../../TranslatedText";

const AppearanceAndSensationAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{__('procedure_mastectomy:accordion_2_heading')}</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_2_paragraph_1" /></p>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_2_paragraph_2" /></p>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_2_paragraph_3" /></p>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_2_paragraph_4" /></p>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_2_paragraph_5" /></p>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_2_paragraph_6" /></p>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_2_paragraph_7" /></p>
        </AccordionItemBody>
    </AccordionItem>
)

export default AppearanceAndSensationAccordionItem
