import React from 'react'
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import ReconstructionAccordion from "../ReconstructionAccordion"
import TranslatedText from "../../TranslatedText";
import {__} from "../../../services/i18n";

const ReconstructionAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{__('procedure_other:accordion_2_heading')}</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_other:accordion_2_paragraph_1" /></p>
            <ReconstructionAccordion />
        </AccordionItemBody>
    </AccordionItem>
)

export default ReconstructionAccordionItem
