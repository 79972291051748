import React from 'react'
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import TranslatedText from "../../TranslatedText"
import {__} from "../../../services/i18n"

const LymphNodeSurgeryAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{__('procedure_other:accordion_1_heading')}</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_other:accordion_1_paragraph_1" /></p>
            <p><TranslatedText translationKey="procedure_other:accordion_1_paragraph_2" /></p>
            <p><TranslatedText translationKey="procedure_other:accordion_1_paragraph_3" /></p>
            <p><TranslatedText translationKey="procedure_other:accordion_1_paragraph_4" /></p>
        </AccordionItemBody>
    </AccordionItem>
)

export default LymphNodeSurgeryAccordionItem
