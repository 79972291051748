import React from "react"
import { Accordion } from "react-accessible-accordion"

import LymphNodeSurgeryAccordionItem from "./other/LymphNodeSurgeryAccordionItem"
import ReconstructionAccordionItem from "./other/ReconstructionAccordionItem"

const OtherProceduresAccordion = () => (
    <Accordion className="margin-top">
        <LymphNodeSurgeryAccordionItem />
        <ReconstructionAccordionItem />
    </Accordion>
)

export default OtherProceduresAccordion
