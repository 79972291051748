import React from 'react'

const DropdownNav = ({ buttonElementWhenNotShown, buttonElementWhenShown, children }) => {
    const [showOptions, setShowOptions] = React.useState(false)

    const handleToggle = () => {
        setShowOptions(!showOptions)
    }

    const handleBlur = (event) => {
        if (!event.relatedTarget) {
            setShowOptions(false)
        }
    }

    return (
        <div className="dropdown-nav" onBlur={ handleBlur }>
            <button className={`trigger-btn ${showOptions ? 'show' : 'hide' }`} onClick={ handleToggle } >
                {
                    (showOptions && buttonElementWhenShown) || buttonElementWhenNotShown
                }
            </button>

            {
                showOptions && children
            }
        </div>
    )
}

export default DropdownNav
