import React from "react"
import { Accordion } from "react-accessible-accordion"

import AppearanceAndSensationAccordionItem from "./bilateralMastectomyItems/AppearanceAndSensationAccordionItem"
import CancerRiskAfterBilateralMastectomyAccordionItem from "./bilateralMastectomyItems/CancerRiskAfterBilateralMastectomyAccordionItem"
import RecoveryTimeAccordionItem from "./bilateralMastectomyItems/RecoveryTimeAccordionItem"
import ShortAndLongTermEffectsAccordionItem from "./bilateralMastectomyItems/ShortAndLongTermEffectsAccordionItem"

const BilateralMastectomyAccordion = () => (
    <Accordion>
        <ShortAndLongTermEffectsAccordionItem />
        <AppearanceAndSensationAccordionItem />
        <RecoveryTimeAccordionItem />
        <CancerRiskAfterBilateralMastectomyAccordionItem />
    </Accordion>
)

export default BilateralMastectomyAccordion
