import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import TranslatedText from "../../TranslatedText"
import {__} from "../../../services/i18n"

import fourToFivePercentRiskImage from "../../../resources/images/risks/4-5_percent_risk.png"
import twoToThreePercentRiskImage from "../../../resources/images/risks/2-3_percent_risk.png"

const CancerRiskAfterMastectomyAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{__('procedure_mastectomy:accordion_4_heading')}</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_4_paragraph_1" /></p>
            <img
                alt="Illustration of 4 to 5 of every 100 young adults"
                className="img-fluid mx-auto"
                src={ fourToFivePercentRiskImage }
            />
            <hr />

            <p><TranslatedText translationKey="procedure_mastectomy:accordion_4_paragraph_2" /></p>

            <img
                alt="Illustration of 2 to 3 of every 100 young adults"
                className="img-fluid mx-auto margin-bottom-14"
                src={ twoToThreePercentRiskImage }
            />

            <p className="color-primary-blue">
                <strong>
                    <TranslatedText translationKey="procedure_mastectomy:accordion_4_paragraph_3" />
                </strong>
            </p>

            <p><TranslatedText translationKey="procedure_mastectomy:accordion_4_paragraph_4" /></p>

            <hr />

            <h4 className="color-primary-blue">
                <TranslatedText translationKey="procedure_mastectomy:accordion_4_paragraph_5" />
            </h4>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_4_paragraph_6" /></p>
        </AccordionItemBody>
    </AccordionItem>
)

export default CancerRiskAfterMastectomyAccordionItem
