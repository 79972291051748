import React from "react"

import PageNavigator from "../../components/PageNavigator"
import TreatmentOptionsTable from "../../components/TreatmentOptionsTable"
import {__} from "../../services/i18n";

const TreatmentComparisonPage = ({ navigation }) => {
    return (
        <section className="__comparison__">
            <h1>{__("treatment_table:heading")}</h1>

            <TreatmentOptionsTable />

            <PageNavigator navigation={ navigation } />
        </section>
    )
}

export default TreatmentComparisonPage
