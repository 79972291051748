import React from 'react'

import { __ } from "../services/i18n"

import resources from "../resources/data/resources.json"

import "./ResourcesPage.css"

const ResourcesPage = () => (
    <section className="__resources__">
        <h1>{ __('resources:heading') }</h1>

        <p>{ __('resources:paragraph_1') }</p>

        {
            resources.links.map((resource, index) => (
                <p key={ index }>
                    <a className="color-text-teal" target="_blank" rel="noopener noreferrer" href={ resource.href }>
                        { resource.key ? __(resource.key) : resource.title }
                    </a>
                </p>
            ))
        }
    </section>
)

export default ResourcesPage
