import { __ } from "../services/i18n"
import { isString } from "./utilities"

export const getRouteData = (navigationPageObject, defaultTranslationKey) => {
    if (!navigationPageObject) {
        return {
            available: false
        }
    }

    const isStringObject = isString(navigationPageObject)

    return {
        available: true,
        route: isStringObject ? navigationPageObject : navigationPageObject.route,
        text: __(isStringObject ? defaultTranslationKey : navigationPageObject.i18nKey)
    }
}
