import React, { useState } from 'react'

import { __ } from "../services/i18n"

import bilateralMastectomyImage from "../resources/images/treatments/bilateral_mastectomy_option.png"
import lumpectomyImage from "../resources/images/treatments/lumpectomy_option.png"
import mastectomyImage from "../resources/images/treatments/mastectomy_option.png"

import "./TreatmentOptionsTable.css"
import TranslatedText from "./TranslatedText";

const TreatmentOptionsTable = () => {
    const [show_bilateral, toggleBilateral] = useState(false)

    const handleShowBilateralSection = (event) => {
        event.preventDefault()
        toggleBilateral(!show_bilateral)
    }

    return (
        <div className="__treatment_table__">
            <div className="table-responsive">
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th className="border-0 "></th>
                            <th className="border-0 procedure-heading text-center">
                                { __('treatment_table:lumpectomy_heading') }
                            </th>
                            <th className="border-0 procedure-heading text-center">
                                { __('treatment_table:mastectomy_heading') }
                            </th>
                            <th className={`border-0 procedure-heading text-center ${show_bilateral ? "" : "d-none"}`}>
                                { __('treatment_table:bilateral_heading') }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th></th>
                            <td>
                                <img alt="" src={ lumpectomyImage } className="img-fluid d-block mx-auto" />
                            </td>
                            <td className="procedure-column">
                                <img alt="" src={ mastectomyImage } className="img-fluid d-block mx-auto" />
                            </td>
                            <td className={`procedure-column ${show_bilateral ? "" : "d-none"}`}>
                                <img alt="" src={ bilateralMastectomyImage } className="img-fluid d-block mx-auto" />
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_1_heading') }
                            </th>
                            <td>
                                <p>{ __('treatment_table:category_1_lumpectomy_paragraph_1') }</p>
                            </td>
                            <td>
                                <p>{ __('treatment_table:category_1_mastectomy_paragraph_1') }</p>
                                <p>
                                    <button className="color-primary-blue border-0 bg-white p-0" onClick={ handleShowBilateralSection }>
                                        { __('treatment_table:category_1_mastectomy_bilateral_trigger') }
                                    </button>
                                    { __('treatment_table:category_1_mastectomy_paragraph_2') }
                                </p>
                            </td>
                            <td className={`${show_bilateral ? "" : "d-none"}`}>
                                <p>{ __('treatment_table:category_1_bilateral_paragraph_1') }</p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_2_heading') }
                            </th>
                            <td>
                                <p>{ __('treatment_table:category_2_lumpectomy_paragraph_1') }</p>
                                <p>{ __('treatment_table:category_2_lumpectomy_paragraph_2') }</p>
                                <p>{ __('treatment_table:category_2_lumpectomy_paragraph_3') }</p>
                            </td>
                            <td>
                                <p>{ __('treatment_table:category_2_mastectomy_paragraph_1') }</p>
                                <p>{ __('treatment_table:category_2_mastectomy_paragraph_2') }</p>
                                <p>{ __('treatment_table:category_2_mastectomy_paragraph_3') }</p>
                            </td>
                            <td className={`${show_bilateral ? "" : "d-none"}`}>
                                <p>{ __('treatment_table:category_2_bilateral_paragraph_1') }</p>
                                <p>{ __('treatment_table:category_2_bilateral_paragraph_2') }</p>
                                <p>{ __('treatment_table:category_2_bilateral_paragraph_3') }</p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_3_heading') }
                            </th>
                            <td>
                                <p><TranslatedText translationKey='treatment_table:category_3_lumpectomy_paragraph_1' /></p>
                                <p><TranslatedText translationKey='treatment_table:category_3_lumpectomy_paragraph_2' /></p>
                            </td>
                            <td>
                                <p><TranslatedText translationKey='treatment_table:category_3_mastectomy_paragraph_1'/></p>
                                <p><TranslatedText translationKey='treatment_table:category_3_mastectomy_paragraph_2'/></p>
                            </td>
                            <td className={`${show_bilateral ? "" : "d-none"}`}>
                                <p><TranslatedText translationKey='treatment_table:category_3_bilateral_paragraph_1'/></p>
                                <p><TranslatedText translationKey='treatment_table:category_3_bilateral_paragraph_2'/></p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_4_heading') }
                            </th>
                            <td>
                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_lumpectomy_label_1" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_lumpectomy_value_1" /></p>

                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_lumpectomy_label_2" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_lumpectomy_value_2" /></p>

                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_lumpectomy_label_3" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_lumpectomy_value_3" /></p>

                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_lumpectomy_label_4" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_lumpectomy_value_4" /></p>
                            </td>
                            <td>
                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_mastectomy_label_1" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_mastectomy_value_1" /></p>

                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_mastectomy_label_2" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_mastectomy_value_2" /></p>

                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_mastectomy_label_3" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_mastectomy_value_3" /></p>

                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_mastectomy_label_4" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_mastectomy_value_4" /></p>

                                <p className="clause color-primary-blue"><em><TranslatedText translationKey="treatment_table:category_4_mastectomy_note" /></em></p>
                            </td>
                            <td className={`${show_bilateral ? "" : "d-none"}`}>
                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_bilateral_label_1" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_bilateral_value_1" /></p>

                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_bilateral_label_2" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_bilateral_value_2" /></p>

                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_bilateral_label_3" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_bilateral_value_3" /></p>

                                <p className="mb-0"><TranslatedText translationKey="treatment_table:category_4_bilateral_label_4" /></p>
                                <p className="weight-600 color-primary-blue"><TranslatedText translationKey="treatment_table:category_4_bilateral_value_4" /></p>

                                <p className="clause color-primary-blue"><em><TranslatedText translationKey="treatment_table:category_4_bilateral_note" /></em></p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_5_heading') }
                            </th>
                            <td>
                                <p><TranslatedText translationKey="treatment_table:category_5_lumpectomy_paragraph_1" /></p>
                                <p className="statistic"><TranslatedText translationKey="treatment_table:category_5_lumpectomy_paragraph_2" /></p>
                                <p><TranslatedText translationKey="treatment_table:category_5_lumpectomy_paragraph_3" /></p>
                                <p className="statistic"><TranslatedText translationKey="treatment_table:category_5_lumpectomy_paragraph_4" /></p>
                                <p><TranslatedText translationKey="treatment_table:category_5_lumpectomy_paragraph_5" /></p>
                            </td>
                            <td>
                                <p><TranslatedText translationKey="treatment_table:category_5_mastectomy_paragraph_1"/></p>
                                <p className="statistic"><TranslatedText translationKey="treatment_table:category_5_mastectomy_paragraph_2"/></p>
                                <p><TranslatedText translationKey="treatment_table:category_5_mastectomy_paragraph_3"/></p>
                                <p className="statistic"><TranslatedText translationKey="treatment_table:category_5_mastectomy_paragraph_4"/></p>
                                <p><TranslatedText translationKey="treatment_table:category_5_mastectomy_paragraph_5"/></p>
                            </td>
                            <td className={show_bilateral ? "" : "d-none"}>
                                <p><TranslatedText translationKey="treatment_table:category_5_bilateral_paragraph_1"/></p>
                                <p className="statistic"><TranslatedText translationKey="treatment_table:category_5_bilateral_paragraph_2"/></p>
                                <p><TranslatedText translationKey="treatment_table:category_5_bilateral_paragraph_3"/></p>
                                <p className="statistic"><TranslatedText translationKey="treatment_table:category_5_bilateral_paragraph_4"/></p>
                                <p><TranslatedText translationKey="treatment_table:category_5_bilateral_paragraph_5"/></p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_6_heading') }
                            </th>
                            <td>
                                <p><TranslatedText translationKey="treatment_table:category_6_lumpectomy_paragraph_1" /></p>
                            </td>
                            <td>
                                <p><TranslatedText translationKey="treatment_table:category_6_mastectomy_paragraph_1" /></p>
                            </td>
                            <td className={show_bilateral ? "" : "d-none"}>
                                <p><TranslatedText translationKey="treatment_table:category_6_bilateral_paragraph_1" /></p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_7_heading') }
                            </th>
                            <td>
                                <p><TranslatedText translationKey="treatment_table:category_7_lumpectomy_paragraph_1"/></p>
                                <p><TranslatedText translationKey="treatment_table:category_7_lumpectomy_paragraph_2"/></p>
                            </td>
                            <td>
                                <p><TranslatedText translationKey="treatment_table:category_7_mastectomy_paragraph_1" /></p>
                            </td>
                            <td className={ show_bilateral ? "" : "d-none" }>
                                <p><TranslatedText translationKey="treatment_table:category_7_bilateral_paragraph_1" /></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TreatmentOptionsTable
