import React from 'react'
import { Accordion } from 'react-accessible-accordion'

import RecoveryTimeAccordionItem from "./reconstruction/RecoveryTimeAccordionItem"
import TissueReconstructionAccordionItem from "./reconstruction/TissueReconstructionAccordionItem"
import TwoStageOrSingleStageImplantReconstructionAccordionItem from "./reconstruction/TwoStageOrSingleStageImplantReconstructionAccordionItem"

const ReconstructionAccordion = () => (
    <Accordion>
        <TwoStageOrSingleStageImplantReconstructionAccordionItem />
        <TissueReconstructionAccordionItem />
        <RecoveryTimeAccordionItem />
    </Accordion>
)

export default ReconstructionAccordion
