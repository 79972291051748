import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import TranslatedText from "../../TranslatedText"
import {__} from "../../../services/i18n"

import onePercentImage from "../../../resources/images/risks/1_percent_risk.png"

const CancerRiskAfterBilateralMastectomyAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{__('procedure_mastectomy:accordion_5_4_heading')}</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_5_4_paragraph_1" /></p>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_5_4_paragraph_2" /></p>

            <img
                alt="1 of every 100 young adults"
                className="img-fluid mx-auto"
                src={ onePercentImage }
            />

            <hr />

            <h4 className="color-primary-blue"><TranslatedText translationKey="procedure_mastectomy:accordion_5_4_paragraph_3" /></h4>
            <p><TranslatedText translationKey="procedure_mastectomy:accordion_5_4_paragraph_4" /></p>
        </AccordionItemBody>
    </AccordionItem>
)

export default CancerRiskAfterBilateralMastectomyAccordionItem
