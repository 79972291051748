import React from 'react'

import PageNavigator from "../../components/PageNavigator"
import QuestionScale from "../../components/QuestionScale"
import {__} from "../../services/i18n"

const ImportancePage = ({
    navigation,
    onSaveProgress,
    savedValues
}) => (
    <section className="__importants__">
        <h1>{__('whats_important:heading_1')}</h1>

        <p>{__('whats_important:paragraph_1')}</p>

        <p className="alert alert-app-info my-4">{__('whats_important:paragraph_2')}</p>

        <h2>{__('whats_important:heading_2')}</h2>

        <QuestionScale savedValues={ savedValues } onSaveProgress={ onSaveProgress } />

        <PageNavigator navigation={ navigation } />
    </section>
)

export default ImportancePage
