import React from 'react'

const ChevronDoubleUpIcon = () => (
    <svg
        className="bi bi-chevron-double-up"
        fill="currentColor"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708z"
            fillRule="evenodd"
        />
        <path
            d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
            fillRule="evenodd"
        />
    </svg>
)

export default ChevronDoubleUpIcon
