import React from 'react'

import MastectomyAccordion from "../accordions/MastectomyAccordion"
import TranslatedText from "../TranslatedText"
import { __ } from "../../services/i18n"

const MastectomyProcedureInformation = () => (
    <section className="__mastectomy_info__ margin-top">
        <h2>{ __('procedure_mastectomy:heading') }</h2>

        <p><TranslatedText translationKey="procedure_mastectomy:paragraph_1" /></p>

        <p>{ __('procedure_mastectomy:paragraph_2') }</p>

        <div className="row">
            <div className="col-12">
                <MastectomyAccordion />
            </div>
        </div>
    </section>
)

export default MastectomyProcedureInformation
