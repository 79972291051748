import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import { __ } from "../../../services/i18n"
import TranslatedText from "../../TranslatedText";

const ShortAndLongTermEffectsAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>{ __('procedure_lumpectomy:accordion_1_heading') }</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p><TranslatedText translationKey="procedure_lumpectomy:accordion_1_paragraph_1" /></p>
            <p><TranslatedText translationKey="procedure_lumpectomy:accordion_1_paragraph_2" /></p>
            <p><TranslatedText translationKey="procedure_lumpectomy:accordion_1_paragraph_3" /></p>
            <p><TranslatedText translationKey="procedure_lumpectomy:accordion_1_paragraph_4" /></p>
        </AccordionItemBody>
    </AccordionItem>
)

export default ShortAndLongTermEffectsAccordionItem
