import React from "react"

import FollowupQuestions from "../../components/FollowupQuestions"
import PageNavigator from "../../components/PageNavigator"
import QuestionScale from "../../components/QuestionScale"
import TreatmentOptionsTable from "../../components/TreatmentOptionsTable"
import {__} from "../../services/i18n"

const SummaryPage = ({
    followupValues,
    navigation,
    onSaveImportanceQuestion,
    onSaveFollowupQuestion,
    savedValues
}) => (
    <section>
        <h1>{__('summary:heading')}</h1>

        <p>{__('summary:paragraph_1')}</p>

        <div className="no-print">
            <button
                className="btn btn-lg btn-primary hide-from-print d-block mx-auto my-4"
                onClick={ window.print }
            >
                {__('summary:print_button')}
            </button>
        </div>

        <div className="my-4">
            <h2>{__('summary:heading_2')}</h2>
            <TreatmentOptionsTable/>
        </div>

        <div className="my-4">
            <h2 className="my-4">{__('summary:heading_3')}</h2>

            <QuestionScale className="my-4" savedValues={savedValues} onSaveProgress={onSaveImportanceQuestion}/>
        </div>

        <div className="my-4">
            <h2>{__('summary:heading_4')}</h2>

            <FollowupQuestions savedValues={ followupValues } onSelection={ onSaveFollowupQuestion } />
        </div>

        <PageNavigator navigation={ navigation } />
    </section>
)

export default SummaryPage
