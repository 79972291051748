import React from 'react'
import { Link } from 'react-router-dom'

import { getRouteData } from "../helpers/NavigationsHelper"

import './PageNavigator.css'

const PageNavigator = ({ navigation }) => {
    const {
        available: hasNextRoute,
        route: nextRoute,
        text: nextRouteText
    } = getRouteData(navigation.next_page, 'navigator:next_btn')

    const {
        available: hasPrevRoute,
        route: prevRoute,
        text: prevRouteText
    } = getRouteData(navigation.prev_page, 'navigator:prev_btn')

    return (
        <div className="__page_navigator__ margin-top no-print">
            <div className="row">
                <div className="col-6 text-left">
                {
                    hasPrevRoute && (
                        <Link className="btn btn-primary prev-btn" to={ prevRoute }>
                            <span className="pad-left">{ prevRouteText }</span>
                        </Link>
                    )
                }
                </div>
                <div className="col-6 text-right">
                {
                    hasNextRoute && (
                        <Link className="btn btn-primary next-btn" to={ nextRoute }>
                            <span className="pad-right">{ nextRouteText }</span>
                        </Link>
                    )
                }
                </div>
            </div>
        </div>
    )
}

export default PageNavigator
