import React from 'react'

import MultChoiceQuest from "./MultChoiceQuest"
import TranslatedText from "./TranslatedText"
import {exists, toInt} from "../helpers/utilities"
import {__} from "../services/i18n"

import "./FollowupQuestions.css"

const FollowupQuestions = ({
    savedValues,
    onSelection
}) => {
    const handleSelection = (data) => {
        delete data.storedResponse

        // Reset Other field
        if (toInt(data.what_treatment) < 3 && exists(data.what_treatment_other_text)) {
            data.what_treatment_other_text = ""
        } else if (exists(data.what_would) && !data.what_would.includes("6") && exists(data.what_would_other_text)) {
            data.what_would_other_text = ""
        }

        onSelection({
            next: {
                ...savedValues,
                ...data
            }
        })
    }

    // Translations for Question 3 needed to be added to variables as the question is only asked depending on how question 2 is answered
    // As a result the hook for swapping languages would not be able to inject in the translations when a new language is selected
    const q3Question =  __("next_steps:question_3")
    const q3Answers = [
        __("next_steps:question_3_ans_1"),
        __("next_steps:question_3_ans_2"),
        __("next_steps:question_3_ans_3"),
        __("next_steps:question_3_ans_4"),
        __("next_steps:question_3_ans_5"),
        __("next_steps:question_3_ans_6"),
        __("next_steps:question_3_ans_7"),
    ]

    return (
        <div className="__followup_questions__">
            <MultChoiceQuest
                choices={ [
                    __('next_steps:question_1_ans_1'),
                    __('next_steps:question_1_ans_2'),
                    __('next_steps:question_1_ans_3'),
                    __('next_steps:question_1_ans_4'),
                ] }
                name={ "what_treatment" }
                question={ __("next_steps:question_1") }
                storeResult={ handleSelection }
                storedOther={ savedValues.what_treatment_other_text }
                storedResponse={ savedValues.what_treatment }
                type="radio"
                onDetectIfOtherSelected={(index) => index === 3}
            />

            <MultChoiceQuest
                choices={ [
                    <TranslatedText translationKey="next_steps:question_2_ans_1" />,
                    <TranslatedText translationKey="next_steps:question_2_ans_2" />,
                    <TranslatedText translationKey="next_steps:question_2_ans_3" />,
                ] }
                name={ "how_ready" }
                question={ __("next_steps:question_2") }
                storeResult={ handleSelection }
                storedOther={ savedValues.what_treatment_other_text }
                storedResponse={ savedValues.how_ready }
                type="radio"
            />

            {
                exists(savedValues.how_ready) && toInt(savedValues.how_ready) >= 1 && (
                    <MultChoiceQuest
                        choices={q3Answers}
                        name={ "what_would" }
                        question={q3Question}
                        storeResult={ handleSelection }
                        storedOther={ savedValues.what_would_other_text }
                        storedResponse={ savedValues.what_would }
                        type="checkbox"
                        onDetectIfOtherSelected={(index) => index === 6}
                    />
                )
            }
        </div>
    )
}

export default FollowupQuestions
