import React from 'react'
import { Accordion } from 'react-accessible-accordion'

import AppearanceAndSensationAccordionItem from "./lumpectomy/AppearanceAndSensationAccordionItem"
import CancerRiskAfterLumpectomyAndRadiationAccordionItem from "./lumpectomy/CancerRiskAfterLumpectomyAndRadiationAccordionItem"
import RadiationAccordionItem from "./lumpectomy/RadiationAccordionItem"
import RecoveryTimeAccordionItem from "./lumpectomy/RecoveryTimeAccordionItem"
import ShortAndLongTermEffectsAccordionItem from "./lumpectomy/ShortAndLongTermEffectsAccordionItem"

const LumpectomyAccordion = () => (
    <Accordion className="margin-top">
        <ShortAndLongTermEffectsAccordionItem />
        <AppearanceAndSensationAccordionItem />
        <RecoveryTimeAccordionItem />
        <RadiationAccordionItem />
        <CancerRiskAfterLumpectomyAndRadiationAccordionItem />
    </Accordion>
)

export default LumpectomyAccordion
